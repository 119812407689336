"use client";

import { useInView } from "react-intersection-observer";

const HomeInfomation = () => {
  const { ref: refInfo, inView: inViewInfo } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  return (
    <>
      <section>
        <div
          className="container grid items-center mx-auto text-center"
          ref={refInfo}
        >
          <div
            className={`mt-4 ${
              inViewInfo
                ? "visible opacity-100 duration-[1500ms]"
                : "invisible opacity-0 duration-[3000ms]"
            }`}
          >
            <div className="text-sm">
              <div className="text-2xl my-4 font-bold">
                ベーシックインカムを
                <br />
                挑戦者のセーフティーネットに
              </div>
              ドキュメンタリー映画を通して、 <br />
              日本にベーシックインカム制度を普及させたい！
              <br />
              挑戦を映像化するプラットフォームを運営しています。
              <br />
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-12 px-4">
        <div className="container grid items-start gap-6 md:grid-cols-2 mx-auto">
          <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-6 shadow-xs">
            <div className="space-y-2">
              <h3 className="text-xl">これまでの支援総額</h3>
            </div>
            <div className="space-y-1">
              <p className="text-2xl font-bold">82,776,238円</p>
            </div>
          </div>
          <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-6 shadow-xs ">
            <div className="space-y-2">
              <h3 className="text-xl">支援者数</h3>
            </div>
            <div className="space-y-1">
              <p className="text-2xl font-bold">8,122人</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeInfomation;
